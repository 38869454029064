import api from '@utils/api';

export const fetchNews = ({ pageSize, pageNum, game }) => {
  return api.get('/news/list', {
    params: {
      pageSize,
      pageNum,
      search: { game },
    },
  });
};

export const fetchNewsPost = (id) => {
  return api.get(`/news/${id}`);
};
