<template>
  <main-layout>
    <div class="pvp-container">
      <h1 class="title">{{ pageTitle }}</h1>

      <games-filter v-model="gamesIds" @input="onGameChange">
        <template slot="title">{{ $t('games.filter') }}</template>
      </games-filter>

      <loader v-if="isNewsLoading" />
      <div v-else-if="news.length" class="news-list">
        <MainCard
          v-for="card in news"
          :key="card.id"
          :data="card"
          :link-to="`/news/${card.id}`"
        />
      </div>
      <div v-else class="empty">
        <icon name="pvp-swords" />
        <div class="empty-text">{{ $t('news.page_nofiltered') }}</div>
      </div>

      <pvp-pagination
        v-if="showPagination"
        :params="pagination"
        :disabled="isNewsLoading"
        @onchange="setPage"
      />
    </div>

    <template slot="seo-text">
      <p>{{ seoText }}</p>
    </template>
  </main-layout>
</template>

<script>
import MainCard from '@components/v2/cards/MainCard.vue';
import GamesFilter from '@components/GameComponents/GamesFilter.vue';
import Icon from '@components/v2/utils/Icon.vue';
import { fetchNews } from '@src/shared/api/news';

export default {
  name: 'NewsPage',
  components: {
    Icon,
    MainCard,
    GamesFilter,
  },
  page() {
    return {
      title: this.$t('news.page_meta_title', {
        host: window.location.host.toLocaleUpperCase(),
      }),
      meta: [
        {
          description: this.$t('news.page_meta_description'),
        },
      ],
    };
  },
  data() {
    return {
      isNewsLoading: true,
      news: [],
      gamesIds: [],

      pagination: {
        page: 1,
        perPage: 12,
        total: 0,
        perPageSizes: [12, 36, 72],
      },
    };
  },
  computed: {
    showPagination() {
      return (
        this.pagination.total > _.head(this.pagination.perPageSizes)
      );
    },
    seoText() {
      return this.$t('seo.news', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
    pageTitle() {
      return this.$t('news.page_title', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
  },
  created() {
    const { page, games } = this.$route?.query;
    this.pagination.page = page || 1;
    this.gamesIds = games ? [Number(games)] : [];
    this.setQuery();
  },
  methods: {
    setPage({ page, perPage }) {
      this.pagination.page = page;
      this.pagination.perPage = perPage;
      this.setQuery();
    },

    loadNewsListData() {
      return fetchNews({
        pageNum: this.pagination.page - 1,
        pageSize: this.pagination.perPage,
        game: this.gamesIds.length ? this.gamesIds : null,
      })
        .then((data) => {
          this.news = data.items;
          this.pagination.total = data.pagen.totalCount;
        })
        .finally(() => {
          this.isNewsLoading = false;
          window.scrollTo({
            top: 0,
            behaviour: 'smooth',
          });
        });
    },

    setQuery() {
      const query = {
        ...this.$route.query,
        page: this.pagination.page,
        games: this.gamesIds.join(','),
      };

      if (!this.gamesIds.length) {
        delete query.games;
      }

      this.$router.push({ query }).catch(() => ({}));
      this.isNewsLoading = true;
      this.loadNewsListData();
    },

    onGameChange() {
      this.setPage({
        page: 1,
        perPage: this.pagination.perPage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hooper {
  height: auto;
  @include min-tablet() {
    height: 525px;
  }
}

.games-filter {
  margin-top: 30px;

  & + .post-card {
    margin-top: 50px;
  }
}

.loader {
  margin: 20px 0;
}

.post-card {
  margin-top: 12px;
}

.empty {
  text-align: center;
  margin-top: 24;
  background-color: $dark-background;
  border-radius: 4px;
  box-shadow: $default-box-shadow;
  margin-top: 50px;

  @include min-tablet() {
    padding: 24px;
  }

  @include max-tablet() {
    padding: 12px;
  }

  .icon {
    font-size: 30px;
  }

  &-text {
    margin-top: 12px;
    font-weight: bold;
    @include min-tablet() {
      font-size: 24px;
    }
    @include max-tablet() {
      font-size: 18px;
    }
  }
}

.pagination {
  margin-top: 20px;
}

.news-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 24px;
  margin-top: 50px;

  @include max-laptop() {
    grid-template-columns: repeat(2, 1fr);
  }

  @include max-tablet() {
    grid-template-columns: 1fr;
  }
}

.title {
  margin-top: 30px;
}
</style>
