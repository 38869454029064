<template>
  <main-layout class="padded" :is-loading="isLoading">
    <div v-if="content" class="pvp-container">
      <BaseLink
        class="news__link"
        icon-left="arrow-left"
        :to="{ name: 'news', query }"
      >
        {{ $t('news.page_toNewsList') }}
      </BaseLink>

      <div class="content">
        <div class="content-wrapper">
          <h1 class="content-title">{{ content.name }}</h1>

          <game-tag
            v-for="game in content.games"
            :id="game"
            :key="game"
          />

          <span class="content-date">{{
            getDate(content.tsPublishTime)
          }}</span>

          <BaseSocialLinks
            :share-title="content.name"
            :share-img="content.imgPreview"
            :share-description="content.previewText"
            class="soc"
          />

          <div class="content-detail">
            <div
              v-if="content.imgBackgr"
              class="preview-image"
              :style="{
                backgroundImage: `url(${content.imgBackgr})`,
              }"
            ></div>
            <!-- eslint-disable vue/no-v-html -->
            <wysiwyg-viewer v-html="content.detailText" />
          </div>

          <BaseSocialLinks
            :share-title="content.name"
            :share-img="content.imgPreview"
            :share-description="content.previewText"
            class="soc"
          />
        </div>
      </div>

      <div v-if="cards.length" class="cards">
        <span class="cards-title">{{ $t('news.page_also') }}</span>
        <BaseLink :to="{ name: 'news' }" icon-right="arrow-right">
          {{ $t('news.all') }}
        </BaseLink>
        <div class="news-list">
          <MainCard
            v-for="card in cards"
            :key="card.id"
            :data="card"
            :link-to="`/news/${card.id}`"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainCard from '@components/v2/cards/MainCard.vue';
import WysiwygViewer from '@components/Wysiwyg/WysiwygViewer.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import { fetchNews, fetchNewsPost } from '@src/shared/api/news';
import { getDateString } from '@utils/time.js';

export default {
  name: 'NewsSinglePage',
  components: {
    WysiwygViewer,
    MainCard,
    BaseSocialLinks,
    BaseLink,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  page() {
    return {
      title: this.content?.name,
    };
  },
  data: () => ({
    isLoading: true,
    cards: [],
    query: {},
    content: null,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'news') {
        vm.query = from.query;
      }
    });
  },
  watch: {
    id: {
      handler: 'loadNewsPost',
      immediate: true,
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behaviour: 'smooth',
      });
    },

    loadNewsCards() {
      return fetchNews({
        pageNum: 0,
        pageSize: 4,
        game: this.content?.games || null,
      }).then((data) => {
        this.cards = data.items
          .filter((item) => item.id !== this.id)
          .slice(0, 3);
      });
    },

    loadNewsPost() {
      return fetchNewsPost(this.id)
        .then((data) => {
          this.content = data.item;
          this.scrollTop();
        })
        .finally(() => {
          this.isLoading = false;
          this.loadNewsCards();
        });
    },

    getDate(seconds) {
      return getDateString(seconds, 'ru');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.cards {
  margin-top: 30px;

  &-title {
    font-weight: bold;
    margin-right: 20px;
  }

  .post-card + .post-card {
    margin-top: 12px;
  }
}

.post-card {
  margin-top: 24;

  &:first-of-type {
    margin-top: 12px;
  }
}

.content {
  margin-top: 26px;
  box-shadow: 0 2px 12px 0 rgba(black, 0.11);
  border: 1px solid rgba(white, 0.05);
  border-radius: 6px;
  background-color: rgba($dark-two, 0.41);

  @include min-tablet() {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include max-tablet() {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include max-tablet() {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;
  }

  &-wrapper {
    margin: 0 auto;
    width: 100%;
    @include min-tablet() {
      max-width: 400px;
    }

    @include min-tablet() {
      max-width: 600px;
    }

    @include min-desktop() {
      max-width: 800px;
    }
  }

  &-title {
    margin-bottom: 12px;
    font-size: 24px;
  }

  &-date {
    margin-left: 14px;
    color: rgba(white, 0.6);
    font-size: 13px;
  }

  &-detail {
    margin-top: 20px;
  }
}

.preview-image {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  height: 166px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include min-desktop() {
    height: 416px;
  }
}

.game-tag {
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}

.soc {
  margin-top: 16px;
}

.news-list {
  margin-top: 12px;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 24px;

  @include min-tablet() {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
